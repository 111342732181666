import * as React from "react"

const Video = ({video}) => {
  return (
    <>  
      <div className='video--overlay'/>
      <video loop={true} playsInline={true} muted={true} autoPlay={true} className='bg-image video'>
        <source src={video}/>  
      </video>  
    </>
  )
}

export default Video
