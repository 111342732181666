import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Video from '../components/video'

const IndexPage = () => {
	useEffect(() => {
		const tOne = setTimeout(function () {
			document.querySelector('.masthead').classList.add('active')
		}, 500)
		const tTwo = setTimeout(function () {
			document.querySelector('.caption').classList.add('active')
		}, 1000)
		const tThree = setTimeout(function () {
			document.querySelector('.video--overlay').classList.add('active')
		}, 1750)
		const tFour = setTimeout(function () {
			document.querySelector('.contact--overlay').classList.add('active')
			document.querySelector('.register-button').innerHTML = 'Close X'
		}, 10000)

		return () => {
			clearTimeout(tOne)
			clearTimeout(tTwo)
			clearTimeout(tThree)
			clearTimeout(tFour)
		}
	})
	return (
		<Layout>
			<div className='text light p40'>
				<div className='max-450 z-2 caption'>
					<p className='m0'>At the convergence of Surfers Paradise and Broadbeach</p>
					<p className='m0 right'>is a place where raw natural beauty and the</p>
					<p className='m0 center'>
						<span className='extra-spacer' />
						pulse of life flow as one
					</p>
					<p className='m0 right'>Exhale is an address designed to embrace it all</p>
				</div>
			</div>
			<Video video='https://player.vimeo.com/progressive_redirect/playback/776813846/rendition/1080p/file.mp4?loc=external&signature=d767fa1e12278a96f7b4fc341f9e93a0c274733364b116a01a60425cf7e56252' />
			<a href='https://sieragroup.com.au' target='_blank' rel='noreferrer' aria-label='Siera' className='sierra-logo-wrapper'>
				<div className='siera-logo' />
			</a>
		</Layout>
	)
}

export const Head = () => <Seo title='Home' />

export default IndexPage
